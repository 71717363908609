import Echo from "laravel-echo";
import Pusher from "pusher-js";
import type {Message} from "@js/models/conversations/Messageable";
import type {ContactDataType, ContactInterface} from "@js/models/contacts/Contact";
import type {PopupNotificationType} from "@js/PopupNotifications";
import type {ID} from "@js/APIs/api";

let pusher = Pusher
let echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true
});

export type BroadcastMessage = {
    id: string
    type: string
}
export type IncomingMessageBroadcast = {
    message: Message,
    messageable_id: ID,
    contact: ContactInterface & {properties: ContactDataType}
    // has_sound: boolean,
} & BroadcastMessage

export type PopupBroadcastNotification = {
    popup?: PopupNotificationType
} & BroadcastMessage

export {echo, pusher};
