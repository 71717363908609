<template>
    <v-dropdown :disabled="disabled">
        <slot v-bind="$attrs">
            <div class="button cursor-pointer" v-bind="$attrs">
                {{ !!modelValue ? modelValue.label : placeholder }}
                <icon-btn icon="mdi:menu-down" class="text-xl"/>
            </div>
        </slot>

        <template #popper>
            <div class="flex flex-col gap-2 options max-h-100">
                <input v-if="withSearch" type="search" class="w-full" @input="search">
                <button v-if="placeholder && !required" v-close-popper :class="{active:!modelValue}" @click="select(undefined)">
                    {{ placeholder }}
                </button>

                <Loading :is-loading="isLoading" class="p-4">
                    <template v-if="filteredOptions.length > 0">
                        <button v-for="(option, index) in filteredOptions" :key="option.value ?? index" v-close-popper
                                :class="{active:option.value === modelValue?.value}" @click="select(option)">
                            {{ option.label }}
                        </button>
                    </template>
                    <p v-else class="text-gray-600 p-2">{{ t('common:no_data') }}</p>
                </Loading>

                <div v-if="hasPages" class="flex justify-around">
                    <icon-btn icon="mdi:chevron-right"/>
                    <icon-btn icon="mdi:chevron-left"/>
                </div>
            </div>
        </template>
    </v-dropdown>
</template>

<script setup lang="ts">
import type {Option} from "@components/forms/MultiselectTypes";
import t from "@lang/t";
import {useDebounceFn} from "@vueuse/core";
import {ref, watch} from "vue";
import Loading from "@components/Loading.vue";

defineOptions({
    inheritAttrs: false
})

const props = withDefaults(defineProps<{
    modelValue: Option|undefined,
    options: Option[]
    placeholder?: string,
    hasPages?: boolean,
    required?: boolean,
    isLoading?: boolean,
    withSearch?: boolean,
    searchFunction?: (s: string) => Promise<Option[]>
    disabled?: boolean
}>(), {
    hasPages: false,
    required: false,
    withSearch: false,
    isLoading: false
})

let filteredOptions = ref(props.options)

watch(() => props.options , () => {
    filteredOptions.value = props.options
})

const emits = defineEmits(['update:modelValue'])

function select(option: Option | undefined)
{
    emits('update:modelValue', option)
}

const search = useDebounceFn((e: Event) => {
    let inputEl = e.target as HTMLInputElement

    if(props.searchFunction) {
        props.searchFunction(inputEl.value).then(properties => filteredOptions.value = properties);
    } else {
        filteredOptions.value = inputEl.value ? props.options.filter(option => option.label == inputEl.value) : props.options
    }
}, 500);
</script>

<style scoped lang="scss">
.options {
    > button {

        @apply py-2 px-3;

        &.active {
            @apply bg-primary text-white;
        }

        &:hover:not(.active) {
            @apply bg-gray-100;
        }
    }
}
</style>
