import axios, {type AxiosError} from "axios";
import {useValidationErrors, type ValidationErrors} from "@js/stores/ValidationErrors";
import urlResolver from "@js/routes/UrlResolver";
import type {AxiosResponse} from "axios";
import type {Pagination, PaginationDataObject} from "@components/tables/Pagination";
import type {OrFilter} from "@components/tables/PrimaryTableTypes";
import {useToast} from "vue-toastification";
import t from "@lang/t";
import router from "@js/routes/router";
import {usePlanStore} from "@js/stores/Plan";

export type ID = string | number

export type ResourceWrapper<T> = {
    data: T
}  & Record<string, unknown>

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

/**
 * @deprecated
 * @see use HttpStatusCode from axios instead
 */
export enum HttpCodes {
    'validation' = 422,
    'unauthorized' = 401,
    'thirdParty' = 433,
    'tooManyAttempts' = 429,
    'forbidden' = 403,
    'not_found' = 404,
    'locked' = 423,
    'conflict'=409,
    'payment_required'=402,
    'RequestEntityTooLarge'=413,
    'NO_CONTENT'=204
}
export enum Method {
    'get'='get',
    'post'='post',
    'put'='put',
    'delete'='delete',
}

export type ThirdPartyError = {
    platform: 'facebook'
    http_code: number
    error: {
        message: string,
        type?: string,
        code: number
        error_subcode: number
    }
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = '/api'
axios.defaults.headers.common = {
    'Accept':'application/json'
}

axios.interceptors.request.use((config) => {

    if(!config!.headers!['Account-ID']) {
        config!.headers!['Account-ID'] = urlResolver.getUrlAccountId()
    }

    return config
})

axios.interceptors.response.use(function (response) {
    let validationErrors = useValidationErrors()
    validationErrors.$reset()
    return response;
}, function (error: AxiosError) {
    let validationErrors = useValidationErrors()
    validationErrors.$reset()

    if(error.response?.status === HttpCodes.validation)
    {
        let validationResponse = error.response.data as ValidationErrors;
        validationErrors.$patch({
            errors : validationResponse.errors,
            message : validationResponse.message,
        })
    }

    if(error.response?.status === HttpCodes.thirdParty)
    {
        let platform = (error.response.data as ThirdPartyError).platform;

        switch (platform) {
            case "facebook":
            default:
            // I'm not sure what we want to do here, maybe show a warning?
        }
    }

    if (error.response?.status===HttpCodes.unauthorized &&
        error.response?.request?.responseURL.search("/me")===-1 &&
        error.response?.request?.responseURL.search("/login")===-1) {
       window.location.reload()
    }

    if (error.response?.status === HttpCodes.locked)
    {
        router.push('/accounts').then(() =>{
            useToast().error(t('common:account_locked'))
        })
    }
    if (error.response?.status === HttpCodes.forbidden && error.config?.method!==Method.get)
    {

        // to check if you have message form backend
        const responseData = error.response?.data as { message?: string };
        let message = '';

        if (responseData && typeof responseData.message === 'string'
            && responseData.message.trim().length !== 0
            && responseData.message !== 'This action is unauthorized.') {
            message = responseData.message;
        } else {
            message = t('validations:unauthorized');
        }

        useToast().error(message);

    }
    if (error.response?.status=== HttpCodes.tooManyAttempts)
    {
        useToast().error(t('validations:tooManyAttempts'))
    }
    if (error.response?.status=== HttpCodes.payment_required){
        usePlanStore().show()
    }

    if (error.response?.status=== HttpCodes.RequestEntityTooLarge)
    {
        useToast().error(t('validations:request_entity_too_large'))
    }

    return Promise.reject(error);
});

export type ApiFilterType = Record<string, number|string|string[]>
export type IndexApiOptionsType = {
    cursor?:string,
    page?: number|undefined,
    search?: string|number|undefined,
    filters?: ApiFilterType,
    complex_filters?: OrFilter[],
    limit?: number,
    sort_by?: string|string[]
    direction?: 'asc' | 'desc',
}
export type IndexApiType<T extends PaginationDataObject> = (options: IndexApiOptionsType) =>
    Promise<AxiosResponse<Pagination<T>, any>>;

export interface CrudApiInterface<T extends PaginationDataObject> {
    index?: IndexApiType<T>
    create?: (formData: FormData) =>  Promise<AxiosResponse<ResourceWrapper<T>, any>>
    show?: (id: ID) => Promise<AxiosResponse<ResourceWrapper<T>, any>>
    update?: (id: ID, formData: FormData) => Promise<AxiosResponse<ResourceWrapper<T>, any>>
    delete?: (id: ID) => Promise<AxiosResponse<any, any>>
}

export type ValidationErrorResponse = {errors: Record<string, string[]>}
export function showResponseValidationToasts(axiosError: AxiosError<ValidationErrorResponse>)
{
    let response = axiosError.response;
    if(response?.status === axios.HttpStatusCode.UnprocessableEntity) {
        let errors = response?.data.errors ?? [];
        Object.values(errors).forEach((errorStrings: string[]) => errorStrings.forEach((error) => useToast().error(error)))
        return true;
    }

    return false;
}

export default axios;
