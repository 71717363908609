import en from '@lang/shared/en.json'

export default {
    ...en,
    'alaaqat': 'Alaaqat',
    'welcome': 'Nice to meet you {{name}}',
    "general": "General",
    "notifications": "Notifications",
    "security": "Security",
    "reports": "Reports",

    "accounts": "Accounts",
    "invoices": 'Invoices',
    "undefined": "Undefined",
    "users": 'Users',
    "teams": 'Teams',
    "team": 'Team',
    "permissions": 'Permissions',
    "method": 'Method',

    'integrations': 'Integrations',
    'integrate': 'integrate',
    'API': 'API',

    'platforms': 'Channels',
    'platform': 'Channel',
    'conversations': 'Conversations',

    'properties': 'Properties',
    'contacts': 'Contacts',
    'created_by': 'Created By',
    'personal_picture': 'Personal Picture',
    'name': 'Name',
    'firstname': 'Firstname',
    'lastname': 'Lastname',

    'email': 'email',
    'password': 'password',
    'passwordConfirmation': 'Password Confirmation',
    'OR': 'OR',
    '?' : '?',

    'save': 'Save',
    'apply': 'Apply',
    'cancel': 'Cancel',
    'processing': 'Processing',
    'bundle': 'Bundle',
    'bundles': 'Bundles',

    'action': 'Action',
    'actions': 'Actions',
    'create': 'Create',
    'creation': 'Creation',
    'no_data': 'No Data',
    'delete': 'delete',
    'add': 'Add',

    'loading': 'Loading',
    'chatbot': 'Chatbot',
    'IntegrationInProgress': 'Integration in progress',
    'activate': 'activate',
    'deactivate': 'deactivate',
    'inactive': 'inactive',
    'active': 'Active',
    'confirm': 'Confirm',
    'areYouSure': 'Are you sure?',
    'industry': 'Industry',
    'next': 'Next',
    'resend': 'Resent',

    'id': 'ID',
    'search': 'Search',
    'submit': 'Submit',
    'signOut': 'Sign out',
    'reset' : 'Reset',
    'set' : 'Set',
    'somethingWentWrong': 'Something Went Wrong',
    'addedBy': 'Added By',
    'phone': 'Phone',
    'created_at': 'Created At',
    'avatar': 'Avatar',
    'clickHere': 'click here',
    'selected': 'Selected',
    "privacy_policy": "Privacy Policy",
    "google_maps": "Google Maps",
    'group': 'Group',
    'groups': 'Groups',
    'type': 'Type',
    'tag_create_placeholder': 'Press enter to create a tag',
    'tag_placeholder': 'Select an option or create new by typing into this field',
    'desc': 'Description',
    'system': 'System',
    'tableTail': 'showing {{limit}} of {{total}}',
    'menu' : 'Menu',
    'details': 'Details',
    'image': 'image',
    'export': 'Export',
    'import': 'Import',
    'exchange': 'Exchange',
    'filter': 'Filter',
    'view': 'View',
    'per_page': 'per page',
    'all': 'All',

    'activities': 'Activities',
    'by': 'By',
    'operation': 'Operation',
    'value': 'Value',
    'and': 'And',
    'or': 'Or',
    'description':'Description',
    'users_count':'Users Count',
    'new_users':'New Users',
    'status':"Status",
    'added_at':'Added At',
    'continue': 'Continue',
    'title':'Title',
    '_id': 'ID',
    'notes':'Notes',
    'contact_details':'Contact Details',
    'contact_activities':'Contact Activities',
    'account_locked':'Your access to this account has been disabled by the account administrator.',
    'updated_at':'Updated at',
    'uploaded_files': 'Uploaded files',
    'the_format': 'The format',
    'timeZone':'time Zone',
    'code': 'Code',
    'account':'Account',
    'deleted': 'Deleted',
    'you': 'You',
    'language': 'Language',
    'soon': 'Coming soon',
    'header': 'Header',
    'body': 'Body',
    'footer': 'Footer',
    'buttons': 'Buttons',
    'button': 'Button',
    'url': 'URL',
    "phone_input_placeholder": 'Enter a phone number',
    "copy": 'Copy',
    'content_copied': 'Content Copied',
    'selected_number':'{{number}} selected',
    'optional': 'Optional',
    'use': 'Use',
    'send': 'Send',
    'sync': 'Sync',
    'previous':'Previous',
    'update':'update',
    'shared': 'shared',
    'attachment':'Attachment',
    'text':'Text',
    'plan':'Plan',
    'free_plan':'Free Plan',
    'free':'Free',
    'starter_plan':'Starter Plan',
    'starter':'Starter',
    'business':'Business',
    'business_plan':'Business Plan',
    'enterprise_plan':'Enterprise Plan',
    'upgrade_plan_description':"You've hit your limit. Please choose from one of our paid plans to continue.",
    'upgrade_to_premium':'Upgrade to Premium',
    'messages_per_month':'{{messages_per_month}} sent messages per month',
    "number_of_users_in_account": " Number of users in account: {{ users }}",
    'pre_defined_responses':'Canned responses: {{ pre_defined_responses }}',
    'available_platforms': 'Number of available platforms for each platform: Facebook ({{ facebook }}), WhatsApp ({{ whatsapp }}), and Instagram ({{ instagram }})',
    'contacts_export':'Contacts Export',
    'broadcast': 'Send broadcast to previously engaged contacts',
    'whatsapp_templates': 'WhatsApp Templates: {{ whatsapp_templates }}',
    'free_plan_description':'A plan available to everyone so you can enter the market at no cost.',
    'starter_plan_description':'Essential features and functionalities at a relatively low cost, affordable for startups',
    'business_plan_description':'the ideal solution which offers a top-notch comprehensive range of features and benefits for businesses value their customers',
    'enterprise_plan_description':'Highest-level offering and are designed specifically to help large organizations scale with confidence',
    'start_free_plan':'Start Free Plan',
    'start_starter_plan':'Start Starter Plan',
    'start_business_plan':'Start Business Plan',
    'contact_us':'Contact us',

    // whatsapp template category
    'category':'Category',
    'automatic':'Automatic',
    'AUTHENTICATION':'Authentication',
    'MARKETING':'Marketing',
    'UTILITY':'Utility',

    'deals':'Deals',
    'stage':'Stage',
    'billing': 'Billing',
    'total': 'Total',
    'tax': 'Tax',
    'close': 'Close',

    'subtotal': 'Subtotal',
    'discount': 'Discount',
    'credit': 'Credit Deduct',
    'credit_to_balance': 'Credit Reservation',
    'grand_total': 'Grand Total',
    'subscription_type':'subscription type',
    'yearly':'Yearly',
    'monthly':'Monthly',

    "documentation": "Documentation",
    'sync_messages':"sync messages",
    'message': 'Message',

    'from': 'From',
    'to': 'To',
    'edited':'Edited',

    'support_tickets_system':'Support Tickets System',
    'attachments':'Attachments',
    'tickets':'Tickets',

    'ad_title':'Ad Title',
    'product_id':'Product ID',
    'post_id':'Post ID',
    'business_bout':'business\'s About',
    'address':'Address',
    'website_links':'Website Links',
    'picture': 'Picture',

    'read_all_messages':'Read all Messages',
    'copy_and_close':'Copy and Close',
    'page_link':'Page Link',

    'unblock_contact_from_conversions':'Unblock Contact from Conversations',
    'block_contact_from_conversions':'Block Contact from Conversations',

    // 404 page
    'but_do_not_worry_you_can_find_plenty_of_other_things_on_our_home_page':
        'But dont worry, you can find plenty of other things on our homepage.',
    'go_to_home_page':'Go To Home Page',
    'sorry_we_could_not_find_this_page':'Sorry, we couldn\'t find this page.',

    'support':'Support',
    'open': 'Open',
    'true': 'True',
    'false': 'False',
    'merge':'Merge',
    'contact':'Contact',
    'element':'Element',
    'add_new_item': 'Add new item',
    'template' : 'Template',
    'content': 'Content',
    'misc': 'Misc',

    'show_details': 'Show details',
    'start_typing_to_search': 'Start typing to search',
    'invalid_filters': 'Filter criteria is not complete.',
}
