export default {
    'bundle_name': 'Bundle Name',
    'new_bundle': 'New Bundle',
    'no_added_platforms': 'No channels added',
    'newPlatform': 'New Channel',
    'choosePlatformToIntegrate': 'Choose the channel you want to integrate with',
    'conversations_bundles': 'Conversations',
    'fb_authorization_expired': 'Facebook authorization expired.',
    'fb_not_authorized': 'Facebook has unauthorized the request, please try again.',
    'fb_unknown': 'You may not be logged-in to facebook, please login using this browser and try again.',
    'youCantDeleteWaba': "You can't delete a WABA before deleting its numbers",
    'needsReauthenticate': 'Click here to reauthenticate',
    'youShouldAddPlatforms': 'You should add a channel to receive messages،',
    'toAddSomePlatforms': 'to add some channels.',
    'pageAlreadyExistOnDifferentAccount': 'Page {{page}} already exist on a different account.',
    'wabaAlreadyExistOnDifferentAccount': 'Whatsapp {{waba}} already exist on a different account.',
    'instagramAlreadyExistOnDifferentAccount': 'Instagram {{instagram}} already exist on a different account.',
    'platform_is_in_another_account': 'channel {{platform}} is integrated with another account, you can\'t integrate the same channel with multiple accounts.',
    'youNeedToActivateYourPlatform': 'To start receiving messages from a channel after integrating with it, you must activate it. To activate a channel, click on ',
    'youWillLoseAccessToMessages': "Disabling this channel means you will lose access to its messages. Are you certain you want to proceed with this action?",
    'you_need_to_reintegrate': 'This channel integration is missing some permissions, you should reintegrate in order for it to work properly, to reintegrate click here.',
    'inbox':'Inbox',
    'predefined_responses':'Canned responses',
    'predefined_responses_desc': 'Pre-written messages that can be quickly used to respond to common questions, issues, or inquiries in conversations.',
    'broadcast':'WhatsApp Broadcast',
    'broadcast_desc':'Broadcast allows you to send a message or media to several contacts at once, you can also use it to start a conversation with a customer.',
    'send_broadcast':'Broadcast',
    'new_template':'New Template',
    'new_response':'New Response',
    'search_title':'Search by title',
    'seen': 'Seen',
    'reply': 'Reply',
    'text': 'text',
    'template': 'template',
    'templates': 'templates',
    'broadcast_templates': 'broadcast templates',
    'broadcast_template': 'broadcast template',
    'select_template': 'Select template',
    'human_agent_usage_warning': 'Sending promotional content after the 24-hour window violates Meta\'s policy, it may result in restrictions on the Page or Instagram account\'s ability to send messages.',
    'send_tagged_message': 'Send Tagged Message',
    'tagged_message_use': 'Message Tags allow you to send a message outside the standard messaging window. These messages are personally relevant updates for a person.',
    'tagged_message_policy': 'Message Tags may not be used to send promotional content, including but not limited to: deals, offers, coupons, and discounts. Use of Message Tags outside the approved use cases may result in restrictions on the Page or Instagram account\'s ability to send messages.',
    'allowed_usage': 'Allowed Usages',
    'disallowed_usage': 'Disallowed Usages (non-exhaustive)',

    'ACCOUNT_UPDATE_allowed_usage': [
        'A notification for a change in status for an application, such as for a credit card or job application',
        'A notification for suspicious activity, such as fraud alerts',
    ],
    'ACCOUNT_UPDATE_disallowed_usage': [
        'Promotional content, including but not limited to deals, promotions, coupons, and discounts Recurring content (e.g., statement is ready, bill is due, new job listings)',
        'Prompts to any survey, poll, or reviews unrelated to a preceding interaction in Messenger',
    ],
    'CONFIRMED_EVENT_UPDATE_allowed_usage': [
        'A reminder for an upcoming class, appointment, or event that a user has scheduled',
        'A confirmation for a user\'s reservation or attendance to an accepted event or appointment',
        'A notification for a user\'s transportation or scheduled trip, such as arrival, cancellation, baggage delay, or other travel status changes',
    ],

    'CONFIRMED_EVENT_UPDATE_disallowed_usage': [
        'Promotional content, including but not limited to deals, offers, coupons, and discounts',
        'Content related to an event the user has not signed up for (e.g., reminders to purchase event tickets, cross-sell of other events, tour schedules, etc)',
        'Messages related to past events',
        'Prompts to any survey, poll, or reviews unrelated to a preceding interaction in Messenger',
    ],

    'CUSTOMER_FEEDBACK_allowed_usage': [
        'A survey for purchase support feedback',
        'A survey for event feedback',
        'Product reviews',
    ],

    'CUSTOMER_FEEDBACK_disallowed_usage': [
        'The tag can only be used with the Customer Feedback Template. Use in any other form is prohibited and will fail.\n'
    ],

    'POST_PURCHASE_UPDATE_allowed_usage': [
        'A confirmation for a transaction, such as invoices or receipts',
        'A status update for a shipment, such as product in-transit, shipped, delivered, or delayed',
        'A status update requiring a user to take action for an order that the user placed, such a declined credit card, backorder items, or other order updates that require user action',
    ],

    'POST_PURCHASE_UPDATE_disallowed_usage': [
        'Promotional content, including but not limited to deals, promotions, coupons, and discounts',
        'Messages that cross-sell or upsell products or services',
        'Prompts to any survey, poll, or reviews unrelated to a preceding interaction in Messenger',
    ],

    'document': 'document',
    'image': 'image',
    'interactive': 'interactive',
    'audio': 'audio',
    'contacts': 'contacts',
    'location': 'location',
    'sticker': 'sticker',
    'video': 'video',
    'more_platforms':'more channels',
    'add_button': 'Add a button',
    'copy_offer_code': 'Copy offer code',
    'offer_code': 'Offer code',
    'see_all_options': 'See all options',
    'this_chat_is_outside_the_24_window': 'You can\'t send a message to this conversation because it\'s outside the 24-hour response time window.',
    'this_chat_is_outside_the_14_days_window': 'You can\'t send a message to this conversation because it\'s outside the 14-days response time window.',
    'use_$_instead': "Use {{$}} instead",

    'broadcast_status_APPROVED': 'Approved',
    'broadcast_status_PENDING': 'Pending',
    'broadcast_status_REJECTED': 'Rejected',
    'broadcast_status_PAUSED':'Paused',
    'broadcast_status_IN_APPEAL':'InAppeal',

    'choice_contacts': 'Choose contacts',
    'send_to_interacted_contacts': 'Send messages only to contacts who have previously interacted with this channel',
    'watch_for_spam': 'Spam complaints can lead to your channel account being flagged',
    'receiver_number': 'Number of recipients',
    'choose_receivers': 'Choose receivers',
    'broadcast_is_running_on_background': 'The broadcast is now running in the background; you will be notified once it is done.',
    'bulk_broadcast_are_you_sure_one': "{{count}} contact will receive this broadcast, and you will billed directly from Meta, are you sure you want to continue?",
    'bulk_broadcast_are_you_sure_other': "{{count}} contacts will receive this broadcast, and you will billed directly from Meta, are you sure you want to continue?",
    'template_reject_reason': 'Reject reason',
    'template_example': 'Example (will be used by Meta to approve your template; you can change it upon sending)',
    'template_example_desc': '(will be used by Meta to approve your template; you can change it upon sending)',
    'go_to_share_source': 'go to source',

    'attachment_share': 'Shared with you',
    'attachment_mention': 'Mentioned you',
    'attachment_reply': 'Replied on',

    // error message
    '131051': 'Message cannot be viewed, message type is currently not supported.',

    // broadcast reject reasons @see https://developers.facebook.com/docs/whatsapp/business-management-api/webhooks/components/
    'ABUSIVE_CONTENT': 'Content has been determined to be abusive',
    'INCORRECT_CATEGORY': 'Category has been determined to be incorrect',
    'INVALID_FORMAT': 'Duplicate content or missing examples',
    'SCAM': 'Content has been determined to be a scam',

    'whatsapp_broadcast_reject_solution': "You can edit the template and re-submit for review. Or, if you believe this is incorrect, you can request a review in <a target='_blank' href='https://business.facebook.com/business-support-home'>Business Support.</a>",

    "sync_last_messages":"Sync latest messages and contacts for this page",

    'sent_by': 'Sent by {{fullname}}',
    'channels':'Channels',
    'add_url_button': 'Add URL Button',
    'add_default_action': 'Add Default Action',
    "view_instagram_template": "View Instagram Template",
    "multiple_instagram_template_can_be_added": 'Multiple elements can be added, multiple elements will send a horizontally scrollable carousel of templates. A maximum of 10 elements is supported.',
    'predefined_response_name_desc': 'This name will not be sent with the message, it is just for identifying the response.',

    'no_more_messages' : 'You have reached the oldest message.',
    'forwarded': 'Forwarded',
    'for_internal_whatsapp_use': '(For WhatsApp internal use)',
    'insert_property': 'Insert a property',
    'header_text_example': 'Inserted Property Example',
    'header_text_example_desc': 'This field is required by Meta To help review your content, provide an example of the "{{property}}" variable. Do not include any secret or sensitive information.',

    'feel_free_to': 'Feel free to',
    'contact_us': 'contact us',
    'contact_us_for_help_creating_template': 'if you need help creating your template.',
    'provide_whatsapp_template_examples': 'An example for each variable is required for Meta review, Do not include any secret or sensitive information.',
    'variable_cannot_be_added_twice': 'The same variable cannot be added twice.',
}
